import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import _ from "lodash"

//Components
import SEO from "../components/seo"
import Header from "../components/navigation/header"
import Title from "../components/title"
import Footer from "../components/footer"
import Layout from "../components/layout"

// Styles
import styles from "../styles/pages/loans.module.scss"

export default function Products() {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "loans/1.png" }) {
        childImageSharp {
          fixed(width: 250, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image2: file(relativePath: { eq: "loans/2.png" }) {
        childImageSharp {
          fixed(width: 250, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image3: file(relativePath: { eq: "loans/3.png" }) {
        childImageSharp {
          fixed(width: 250, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image4: file(relativePath: { eq: "loans/4.png" }) {
        childImageSharp {
          fixed(width: 250, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image5: file(relativePath: { eq: "loans/5.png" }) {
        childImageSharp {
          fixed(width: 250, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image6: file(relativePath: { eq: "loans/6.png" }) {
        childImageSharp {
          fixed(width: 250, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image7: file(relativePath: { eq: "loans/7.png" }) {
        childImageSharp {
          fixed(width: 250, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image8: file(relativePath: { eq: "loans/8.png" }) {
        childImageSharp {
          fixed(width: 250, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image9: file(relativePath: { eq: "loans/9.png" }) {
        childImageSharp {
          fixed(width: 250, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image10: file(relativePath: { eq: "loans/10.png" }) {
        childImageSharp {
          fixed(width: 250, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image11: file(relativePath: { eq: "loans/11.png" }) {
        childImageSharp {
          fixed(width: 250, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image12: file(relativePath: { eq: "loans/12.png" }) {
        childImageSharp {
          fixed(width: 250, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  let images = []
  _.forEach(data, val => {
    images.push(val.childImageSharp.fixed)
  })
  return (
    <div>
      <Header />
      <SEO
        title="Solar Bank Loans"
        article={false}
        pathname="/loans"
        description="There are several low-interest bank loans for solar backed by the Asian Development Bank!"
      />
      <section>
        <Layout>
          <Title
            text="Loans &amp; Schemes"
            subtitle="We can assist you with special low interest loans given by the Asian Development Bank for rooftop solar"
          />
          <div className={styles.grid}>
            {images.map((img, idx) => (
              <Img fixed={img} key={idx} />
            ))}
          </div>
        </Layout>
      </section>
      <Footer />
    </div>
  )
}
